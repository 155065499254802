<template>
    <div>
        <CompTable title="栏目更新监测结果" :columns="columns" :dataList="dataList">
            <template #operate>
                <Button @click="onDisplayWhiteListModal">栏目白名单设置</Button>
                <Button type="primary" @click="Route.jump('/columnremindersettingtable')">前去设置</Button>
            </template>
            <ElemChart title="随约平台服务主体栏目逾期更新预警情况" :option="chartOpt" style="height: 35%;margin: 0 10px 10px;width: initial;padding: 15px 0 0;overflow: hidden;"></ElemChart>
        </CompTable>

        <CompModal class="columnreminder-white-list" ref="modal" title="栏目白名单设置" :onSuccess="onSubmitWhiteList">
            <div class="attr-box">
                <div class="name-box">
                    <div class="name">栏目名称</div>
                    <div class="name">栏目编码</div>
                    <div class="operate-add" @click="onAddItem">
                        <Icon class="icon" type="md-add" color="#fff" />
                    </div>
                </div>
                <div class="item-box" v-for="(item, idx) in whiteList" :key="item.item_id">
                    <div class="item-form">
                        <div class="item">
                            <Input class="module" v-model="item.columnName" type="text" placeholder="请输入名称" />
                        </div>
                        <div class="item">
                            <Input class="module" v-model="item.columnCode" type="text" placeholder="请输入编码" />
                        </div>
                    </div>
                    <div class="operate">
                        <div class="operate-item remove" @click="onRemoveItem(idx)">
                            <Icon class="icon" type="md-remove" color="#fff" />
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>

        <!-- <WPSEditor></WPSEditor> -->
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import ElemChart from "../../managsystem/childrens/ElemChart.vue"
import Request from "../../jointly/utils/request"
import Route from "../../communitymobilization/entity/Route"
import CompModal from "../../residentdatabase/components/CompModal.vue"
// import WPSEditor from "../../wps/components/WPSEditor.vue"

export default {
    components: { CompTable, ElemChart, CompModal },

    data() {
        return {
            Route: Route,

            columns: [
                {
                    title: "类型",
                    key: "dimensionName",
                },
                {
                    title: "服务主体数",
                    key: "serviceNum",
                },
                {
                    title: "逾期更新数",
                    key: "overdueOrgNum",
                    style: {
                        color: "red",
                    },
                    click: p => {
                        Route.jump("/columnreminderdetectionorgdetail", {
                            dimensionId: p.row.dimensionId,
                        })
                    },
                },
            ],

            dataList: null,
            chartOpt: null,

            whiteList: [
                {
                    item_id: this.$core.randomString(),
                },
            ],
        }
    },

    watch: {
        dataList(v) {
            if (v) {
                this.chartOpt = {
                    title: {
                        text: "随约平台服务主体栏目逾期更新预警情况",
                        left: "center",
                        textStyle: {
                            fontSize: 14,
                        },
                    },
                    color: "rgba(255,191,53,1)",
                    xAxis: {
                        type: "category",
                        data: v.map(item => item.dimensionName),
                        axisLabel: {
                            interval: 0,
                            fontSize: 10,
                            formatter: function(value) {
                                var strs = []

                                // 每隔4个字符换行
                                for (var i = 0, s; (s = value.substr(i, 4)); i += 4) {
                                    strs.push(s)
                                }

                                return strs.join("\n")
                            },
                        },
                    },
                    grid: {
                        top: 50,
                        left: "3%",
                        right: "3%",
                        bottom: 40,
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    // dataZoom: [
                    //     {
                    //         type: "slider",
                    //         show: true,
                    //         handleSize: 0, //滑动条的 左右2个滑动条的大小
                    //         startValue: 0, // 初始显示值
                    //         endValue: 8, // 结束显示值
                    //         height: 9, //组件高度
                    //         left: -1, //左边的距离
                    //         right: -1, //右边的距离
                    //         bottom: -1, //底边的距离
                    //         fillerColor: "#ccc",
                    //         borderColor: "#fff",
                    //         backgroundColor: "#fff", //两边未选中的滑动条区域的颜色
                    //         showDataShadow: false, //是否显示数据阴影 默认auto
                    //         showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
                    //         realtime: true, //是否实时更新
                    //         filterMode: "filter",
                    //     },
                    // ],
                    yAxis: {
                        type: "value",
                        min: 1,
                        logBase: 10,
                        type: "log",
                    },
                    series: [
                        {
                            data: v.map(item => item.overdueOrgNum),
                            type: "bar",
                            label: {
                                show: true,
                                position: "top",
                            },
                        },
                    ],
                }
            }
        },
    },

    created() {
        this.getTableData()
    },

    methods: {
        getTableData() {
            Request.get("/gateway/api/symanage/pc/columnAlarm/selectColumnAlarmDimension").then(res => {
                this.dataList = res
            })
        },

        async onDisplayWhiteListModal() {
            if (!this.whiteListInit) {
                await this.getWhiteList()
                this.whiteListInit = true
            }

            this.$refs.modal.display()
        },

        /**
         * 添加单项
         */
        onAddItem() {
            this.whiteList.push({
                item_id: this.$core.randomString(),
            })
        },

        /**
         * 移除单项
         */
        onRemoveItem(idx) {
            this.whiteList.splice(idx, 1)

            // 如果只剩下一个，则添加一个空的
            if (this.whiteList.length === 0) {
                this.whiteList.push({
                    item_id: this.$core.randomString(),
                })
            }
        },

        async getWhiteList() {
            await Request.post("/gateway/api/symanage/pc/columnAlarm/selectAll").then(res => {
                if (!res?.length) {
                    return
                }

                this.whiteList = res.map(v => ({
                    id: v.id,
                    item_id: this.$core.randomString(),
                    columnName: v.columnName,
                    columnCode: v.columnCode,
                }))
            })
        },

        /**
         * 提交白名单
         */
        onSubmitWhiteList() {
            const res = this.whiteList.filter(v => v.columnName && v.columnCode)

            if (!res?.length) {
                return this.$Message.error("请至少填写一项")
            }

            Request.post(
                "/gateway/api/symanage/pc/columnAlarm/saveColumnWhitelist",
                {
                    columnVos: res.map(v => ({
                        id: v.id,
                        columnName: v.columnName,
                        columnCode: v.columnCode,
                    })),
                },
                { json: true }
            ).then(res => {
                this.$Message.success("提交成功")
                this.$refs.modal.close()
            })
        },
    },
}
</script>

<style lang="less">
.columnreminder-white-list {
    .attr-box {
        max-height: 100%;
        padding: 0 20px;
        overflow-y: auto;

        .name-box {
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: 10px 0 5px 0;
            margin-bottom: 5px;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.9);

            .name {
                padding: 0 5px;
                width: 250px;
                margin: 0 10px;
            }

            .value-box {
                width: 300px;
            }

            .operate-add {
                cursor: pointer;
                margin: 0 10px;
                padding: 0 15px;
                font-size: 20px;
                border-radius: 4px;
                background: #6fc3f8;
            }
        }

        .item-box {
            margin-bottom: 10px;
            display: flex;

            .item-form {
                width: 540px;
                display: flex;
                flex-shrink: 0;
            }

            .item {
                margin: 0 10px;

                .module {
                    width: 250px;

                    &.input {
                        height: 32px;
                        line-height: 1.5;
                        padding: 4px 7px;
                        font-size: 14px;
                        border: 1px solid #dcdee2;
                        border-radius: 4px;
                        color: #515a6e;
                        background-color: #fff;
                    }
                }

                .range-box {
                    width: 250px;

                    .range-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .range {
                            width: 90px;
                        }
                    }
                }

                &.value-box {
                    width: 300px;

                    .module {
                        width: 300px;
                    }

                    .range-box {
                        width: 300px;

                        .range-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .range {
                                width: 140px;
                            }
                        }
                    }
                }
            }

            .operate {
                margin: 0 10px;

                .operate-item {
                    cursor: pointer;
                    padding: 0 15px;
                    font-size: 20px;
                    border-radius: 4px;

                    &.add {
                        background: #6fc3f8;
                    }

                    &.remove {
                        background: #f88662;
                    }
                }
            }
        }
    }
}
</style>
